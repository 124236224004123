.teaser-vertical-centered-gray {
    padding: 1.875rem 0.625rem 0.25rem;

    .cmp-teaser__title {
        font-size: 1.5rem;
        line-height: 1.3;
    }

    .cmp-teaser__description {
        font-size: 0.875rem;
        color: #606060;
        ul {
            list-style: disc;
            text-align: left;
            padding-left: 1.6rem;
            margin-block: 0.75rem;
        }
    }
    .cmp-teaser {
        text-align: center;
        background: #efefef;
        flex-direction: column-reverse;
        padding: 2.5rem 2rem 2.25rem;
        display: flex;
        height: 100%;
        box-sizing: border-box;
        justify-content: flex-end;
    }
    .cmp-teaser__image {
        align-self: center;
    }
    .cmp-image__image {
        max-width: 7rem;
    }
    .cmp-teaser__content {
        margin-top: 1.75rem;
    }

    .cmp-teaser__action-link {
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: 700;
        color: $color-accent;
        &:after {
            content: "";
            @include arrow-right-color($color-accent);
            background-repeat: no-repeat;
            width: .6rem;
            height: .7rem;
            margin-left: .5rem;
            display: inline-block;
        }
    }
}