//== XF Header style, used on page template

#main-header {
  box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 20%);
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;

  @media only screen and (max-width: $laptop) {
    flex-wrap: wrap;
  }

  .image {
    @media only screen and (max-width: $tablet) {
      margin-right: auto;
    }

    .cmp-image {
      margin: auto 0.5em;
      width: 4rem;

      @media only screen and (max-width: $tablet) {
        margin: auto 1.3em;
        width: 5rem;
      }
    }
  }


  //=================== Navigation ===================

  .navigation {
    margin-right: auto;

    @media only screen and (max-width: $tablet) {
      display: none;
      margin-right: auto;
      order: 4;
      flex-basis: 100%;
    }
  }

  .cmp-navigation {
    margin-right: 0.5em;

    @media only screen and (max-width: $tablet) {
      height: auto;
      margin: 1rem 0 0;
    }
  }

  .cmp-navigation__group {
   > .cmp-navigation__item--level-0 {
        @media only screen and (max-width: $tablet) {
          position: relative;
          &:after {
            display: inline-block;
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: "";
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
            position: absolute;
            right: 2rem;
            top: 18px;
        }
      }

     > .cmp-navigation__item-link {
        &:after {
          @media only screen and (min-width: calc($tablet + 1px)) {
            display: inline-block;
            margin-left: 0.255em;
            vertical-align: 0.255em; 
            content: "";
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
          }
        }
      }
  }
}

  .cmp-navigation__group {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;

    @media only screen and (max-width: $tablet) {
      display: block;
    }

    @media only screen and (max-width: 1400px) {
      position: initial;
    }

    .cmp-navigation__item {
      @media only screen and (max-width: $tablet) {
        border-bottom: 1px solid #C9C9C9;
        background: #F0F0F0;
      }

      >a {
        @media only screen and (max-width: $tablet) {
          padding: 0.5625rem 1rem 0.6875rem;
        }
      }
    }
  }


  .cmp-navigation__item--level-0 {
    display: inline-flex;
    margin-left: 0.5em;

    @media only screen and (max-width: $tablet) {
      display: block;
      margin-left: 0;

      &.is-open {
        .cmp-navigation__group {
          visibility: visible;
          opacity: 1;
          display: block;
        }
      }
    }

    &>a {
      padding: 2.4rem 1rem;
      @media only screen and (max-width: 1300px) {
        padding: 2.4rem 0.4rem;
      }
    }

    &:hover {
      color: #f40000;

      &>.cmp-navigation__group {
        visibility: visible;
      }

      &>.cmp-navigation__item-link {
        color: #f40000;
      }
    }

    &>.cmp-navigation__group {
      position: absolute;
      top: 6em;
      z-index: 1;
      display: grid;
      grid-template-columns: auto auto auto;
      grid-template-rows: repeat(7, 2.5rem);
      grid-auto-flow: column;
      min-height: 18.75rem;
      width: max-content;
      overflow: hidden;
      background: $color-background;
      border-radius: 0.25em;
      box-shadow: 0 0.25em 0.5em 0.2em $color-shadow;
      visibility: collapse;
      padding-left: 5rem;
      padding-top: 1.25rem;
      width: 78rem;
      left: 0;

      @media only screen and (max-width: $tablet) {
        display: none;
        position: relative;
        top: 0;
        padding: 0;
        background: transparent;
        box-shadow: none;
        min-height: inherit;
      }


      @media only screen and (max-width: 1400px) {
        max-width: 100%;
        width: -webkit-fill-available;
      }


      .table-header,
      .teaser-header {
       display: block;
        @media only screen and (max-width: $tablet) {
          display: none;
        }
      }
    }
  }

  .cmp-navigation__item--level-1 {
    @media only screen and (max-width: $tablet) {
      padding-left: 1rem;
    }

    >a {
      font-weight: 500;
      padding: 0.7rem 1rem;
      text-transform: inherit;
      font-size: 0.875rem;
    }
  }
  .cmp-navigation__item-link {
    display: block;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: $color-shade-4;
    text-decoration: none;

    @media only screen and (max-width: $tablet) {
      display: inline-block;
      font-weight: bold;
    }
  }
  //=================== End Navigation ===================


  //=================== Mobile Menu Button ===================

  >.button {
    @media only screen and (min-width: $tablet) {
      display: none;
    }
  }

  @media only screen and (max-width: $tablet) {
    >.button {
      background-image: url('resources/images/hamburger-icon.png');
      background-size: contain;
      width: 30px;
      height: 30px;
      order: 3;
      margin-left: auto;
      margin-right: 2rem;
      cursor: pointer;

      button {
        background-color: transparent;
        border: 0;
      }

      &.open-menu {
        +.navigation {
          display: block;
        }
      }
    }
  }

  //=================== End Mobile Menu Button ===================


  >div:nth-child(4) {
    display: flex;

    @media only screen and (max-width: $tablet) {
      order: 2;
    }

    @media only screen and (min-width: $tablet) and (max-width: $laptop) {
      order: 5;
      flex-basis: 100%;
      margin-bottom: 1rem;
    }

    >.cmp-container {
      display: inherit;
      @media only screen and (min-width: $tablet) and (max-width: $laptop) {
        width: 100%;
      }
    }
  }

  >div:nth-child(5) {
    @media only screen and (max-width: $tablet) {
      flex-basis: 100%;
      order: 5;
    }

    >.cmp-container {
      display: flex;

      @media only screen and (max-width: $tablet) {
        flex-direction: column;
        width: 100%;
      }

      >div {
        display: inherit;

        @media only screen and (max-width: $tablet) {
          flex-direction: column;
        }
      }
    }

  }

  //===================  Right Navigation ===================

  .header-right-dropdown {
    margin-right: .5rem;

    @media only screen and (max-width: $tablet) {
      margin: 0
    }

    @media only screen and (max-width: $tablet) {
      &.is-open {
        >.cmp-container {
          .container {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
    >.cmp-container {
      position: relative;

      .button {
        width: 100%;
        @media only screen and (min-width: $tablet) {
          margin-bottom: 0.4rem;
        }
      }

      button {
        background: #f40000;
        text-transform: uppercase;
        color: #ffffff;
        margin-left: 5px;
        padding: 9px 22px;
        box-shadow: none;
        border: 0;
        font-size: 12px;
        font-weight: 500;
        border-radius: 5px;
        display: block;



        @media only screen and (max-width: $tablet) {
          width: 100%;
          background: #f0f0f0;
          color: #f40000;
          font-weight: bold;
          font-size: .8rem;
          border-radius: 0;
        }

        @media only screen and (max-width: 1300px) {
          padding: 10px;
        }


        &:after {
          display: inline-block;
          margin-left: 0.7em;
          vertical-align: 0.255em;
          content: "";
          border-top: 0.3em solid;
          border-right: 0.3em solid transparent;
          border-bottom: 0;
          border-left: 0.3em solid transparent;
        }
      }

      .container {
        position: absolute;
        top: 100%;
        display: block;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: visibility 0s, opacity 0.3s linear;
        transition: visibility 0s, opacity 0.3s linear;
        border: 1px solid rgba(0, 0, 0, 0.15);
        background-color: white;
        padding: 2.1rem 0 1.8rem 1.25rem;
        width: 16rem;
        z-index: 2;

        @media only screen and (max-width: $tablet) {
          width: 100%;
        }

        .cmp-button {
          margin-bottom: .5rem;
        }

        .cmp-button__text {
          font-weight: 500;
          font-size: .9rem;
          color: black;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &:hover {
        @media only screen and (min-width: $tablet) {

          .container {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    

      .cmp-button {
        display: block;
        cursor: pointer;
        border: 0;
        text-decoration: none;
        margin: 0;
      }


    }

    &:last-of-type {
      >.cmp-container .container {
        left: -90%;

        @media only screen and (max-width: $tablet) {
          left: 0;
        }
      }
    }

  }

  //===================  End Right Navigation ===================


  //===================  Text  ===================
.container {
  .text {
    @media only screen and (min-width: $tablet) and (max-width: $laptop) {
      width: 100%;
    }
    .cmp-text {
      margin: 0;
    }

    p {
      font-size: .75rem;
      color: #404040;
      line-height: .75rem;
      margin: 0 0.6rem 0 0;
      text-align: right;
      margin-right: .6rem;

      @media only screen and (max-width: $tablet) {
        text-align: center;
      }
    }
  }
}

    //===================  End Text  ===================

}

//===================  Teaser Top Banner ===================


.teaser-top-banner {
  .cmp-teaser__content {
    display: grid;
    grid-template-columns: auto 2rem;
    background: #E3212B;
    padding: 0.5rem 1rem;
    text-align: center;

    .cmp-teaser__description {
      a {
        text-decoration: underline;
      }
    }

    .cmp-teaser__action-container {
      text-align: center;
      line-height: 1.6;
    }

    p {
      color: #fff;
      font-size: .875rem;
      margin: 0;

      @media only screen and (max-width: $mobile) {
        line-height: 1.4;
        margin-block:0.4rem
      }
    }
  }
}

//===================  End Teaser Top Banner ===================

