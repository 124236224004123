.teaser-hero {
  .cmp-teaser {
    position: relative;

    @media only screen and (max-width: $tablet) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .cmp-teaser__content {
    position: absolute;
    top: 50%;
    left: inherit;
    right: 0;
    width: 38rem;
    transform: translateY(-50%);
    border-radius: .8rem 0px 0px .8rem;
    font-family: inherit;
    background: $color-teaser-red;
    padding: 2rem 2.5rem;

    @media only screen and (max-width: $tablet) {
      position: relative;
      transform: inherit;
      border-radius: 0;
      top: 0;
      width: 100%;
      order: 2;
    }
  }


  .cmp-teaser__action-link {
    background: #fff;
    color: #f40000;
    text-transform: uppercase;
    border: none;
    border-radius: 0.5rem;
    padding: 0.75rem 2.5rem;
    font-size: .75rem;
    font-weight: 500;
  }

  .cmp-teaser__title {
    font-size: 2rem;
    margin: 0;
    line-height: 3.0625rem;
    text-decoration: none;
    color: #fff;
  }

  .cmp-teaser__description {
    color: #fff;
    font-size: .9rem;
    padding-right: 3.75rem;
    font-weight: 300;
    line-height: 1.75em;

    @media only screen and (max-width: $tablet) {
      padding-right: 0;
    }
  }

  .cmp-teaser__title,
  .cmp-teaser__description {
    text-align: left;

    @media only screen and (max-width: $tablet) {
      text-align: center;
    }
  }

  .cmp-teaser__action-container {
    justify-content: flex-start;
    margin-bottom: 0;
    margin: 1rem 0;
    display: flex;

    @media only screen and (max-width: $tablet) {
      justify-content: center;
    }
  }
}