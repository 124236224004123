.timeline-item {
    position: relative;
    .cmp-teaser,
    .cmp-text {
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        margin-left: 3rem;
        padding: 1rem;

        &:before {
            content: "";
            border-left: 1px solid $color-accent;
            top: 0;
            bottom: 0;
            left: -1.25rem;
            height: 100%;
            display: block;
            position: absolute;
        }
    }

    .cmp-teaser__description {
        p {
            color: #404040;
            font-size: 0.875rem;
            padding-top: 0.625rem;
        }
    }

    .cmp-teaser__pretitle {
        display: block;
        width: 68px;
        height: 29px;
        line-height: 30px;
        font-weight: 700;
        text-align: center;
        margin-left: -10px;
        margin-right: -10px;
        background: $color-accent;
        color: #fff;
        position: absolute;
        z-index: 2;
        top: 15px;
        font-size: 14px;
        @media only screen and (min-width: $mobile) {
            width: 105px;
            height: 43px;
            line-height: 43px;
            top: 40px;
        }
        &:before {
            height: 0;
            width: 0;
            bottom: -10px;
            left: 0;
            border-top: 10px solid $color-accent;
            border-left: 10px solid transparent;
        }

        &:after,
        &:before {
            content: "";
            position: absolute;
        }
    }

    .cmp-teaser__image:before,
    .cmp-text:after  {
            content: "";
            width: 12px;
            height: 12px;
            border-radius: 50%;
            position: absolute;
            border: 1px solid $color-accent;
            left: -1.625rem;
            top: 0;
            background-color: #fff;
            @media only screen and (min-width: $mobile) {
                width: 20px;
                height: 20px;
                left: -1.9rem;
            }

    }
    .cmp-text:after  {
        top: -0.875rem;
    }

    .cmp-text p {
        background-color: #cf1c28;
        font-size: 1.125rem;
        margin: 0;
        color: #fff;
        padding: 1.2rem 2rem;
        @media only screen and (min-width: $mobile) {
            padding: 1.5rem 3rem;
        }

    }

    @media only screen and (min-width: $mobile) {

        &:nth-child(even) {
            padding-left: 50%;
            padding-right: 0;   

            .cmp-teaser,
            .cmp-text {
                margin-left: 1.2rem;
                padding-left: 2rem;
            }

        }

        &:nth-child(odd) {
            padding-right: 50%;

            .cmp-teaser,
            .cmp-text {
                margin-right: 1.2rem;
                padding-right: 2rem;
                margin-left: 0;
            }

            .cmp-teaser__image:before,
            .cmp-text:after {
                right: -1.9rem;
                left: inherit;
            }

            .cmp-teaser:before,
            .cmp-text:before {
                right: -1.25rem;
                left: inherit;
            }
        }
    }
}