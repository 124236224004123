.container {
    .teaser-vertical-white-button-fill {
        border-right: 1px solid white;
        margin-top: 2rem;
        margin-bottom: 2rem;
        @media only screen and (max-width: $mobile) {
            border-right: 0;
            border-bottom: 1px solid white; 
        } 
        .cmp-teaser {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            padding: 4rem 1rem;
        }
        .cmp-teaser__description {
            max-width: 22.5rem;
            font-size: 0.875rem;
            margin-bottom: 1.875rem;
            font-weight: 300;
            p {
                line-height: 1.313rem; 
                margin-top: 1rem;
            }
        }
        .cmp-teaser__title {
            text-transform: uppercase;
            font-size: 1.5rem;
        }
        .cmp-image__image {
            max-width: 7rem;
        }
        .cmp-teaser__content {
            margin-top: 2.5rem;
            text-align: center;
            color: white;
        }

        .cmp-teaser__action-link {
            background: #fff;
            color: #f40000;
            @include cmp-button;
        }
    }
    .teaser-vertical-white-button-fill:last-child {
        border-right: 0;
        @media only screen and (max-width: $mobile) {
            border-bottom: 0;
            margin-top: 0;
        }
    }
}