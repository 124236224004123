.teaser-table {
    background-color: #fff;
    .cmp-teaser {
        display: flex;
        flex-basis: 100%;
        flex-direction: row-reverse;
        @media only screen and (max-width: $tablet) {
            flex-direction: column-reverse;
        }
    }

    .cmp-teaser__content {
        width: 60%;
        padding: 1.563rem 2.188rem;
        @media only screen and (max-width: $mobile) {
            padding: 1.563rem 1rem;
        }

        @media only screen and (max-width: $tablet) {
            width: auto;
        }
    }

    .cmp-teaser__description {
        font-size: 0.875rem;
        color: $color-text;
    }

    .cmp-teaser__pretitle {
        color: $color-accent;
        font-size: 1.25rem;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 3px;
    }

    .cmp-teaser__image {
        flex-grow: 1;
    }

    .cmp-teaser__action-container {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }

    .cmp-teaser__action-link {
        background: #f40000;
        color: #fff;
        @include cmp-button;
    }

    table {
        width: 100%;
        border: none;
        text-align: center;
        border-spacing: 0.313rem 0.063rem;

        caption {
            font-weight: 700;
            font-size: 2rem;
            line-height: 1.2;
            text-align: left;
            padding-left: 0.313rem;

            @media only screen and (max-width: $tablet) {
                padding-bottom: 0.313rem;
                margin-bottom: 0.75rem;
            }
        }

        td {
            border: none;
            font-size: 0.875rem;
        }

        tr:first-child td {
            background-color: $color-text;
            color: white;
            font-weight: 700;
            padding: 0.438rem 0;
        }

        tr:last-child td {
            background-color: #efefef;
            color: $color-shade-3;
            font-weight: 400;
            padding: 0.625rem 0;

            @media only screen and (max-width: $tablet) {
                background-color: $color-shade-1;
            }
        }
    }
}

.groups-page {
    .teaser-table {
        background: #EFEFEF;
        .cmp-teaser {
            align-items: center;
            @media only screen and (max-width: $tablet) {
                align-items: inherit;
            }

        }
    }
   
    .teaser-table table tr:last-child td {
        background-color: #fff;
    }
}