    .teaser-horizontal-red {
        @include teaser-horizontal-two-buttons;

        .cmp-teaser {
            align-items: inherit;
        }

        .cmp-teaser__image {
            flex: 0 0 50%;

            @media only screen and (max-width: $tablet) {
                flex: 0 0 100%;
            }

            & img {
                object-fit: fill;
            }
        }

        .cmp-teaser__content {
            background-color: $color-teaser-red;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-sizing: border-box;
            flex: 0 0 50%;

            @media only screen and (max-width: $tablet) {
                flex: 0 0 100%;
                align-items: center;
                padding: 2.5rem 1rem 1.625rem 1rem;
            }

            .cmp-teaser__title,
            .cmp-teaser__description {
                color: white;

                @media only screen and (max-width: $tablet) {
                    text-align: left;
                }
            }

            .cmp-teaser__title {
                @media only screen and (max-width: $tablet) {
                    font-size: 1.5rem;
                    font-weight: 500;
                }
            }
        }

        .cmp-teaser__action-container {
            @media only screen and (max-width: $tablet) {
                width: 90%;
            }

            & .cmp-teaser__action-link {
                text-align: center;

                @media only screen and (max-width: $tablet) {
                    width: 100%;
                    box-sizing: border-box;
                }
            }

            & .cmp-teaser__action-link:nth-child(1) {
                background-color: white;
                color: $color-teaser-red;
            }
        }
    }