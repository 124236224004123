.text-center-desktop {
    .cmp-text {
        text-align: center;
        padding-block: 3rem;

        @media only screen and (max-width: $mobile) {
            text-align: left;
        }
    }
}

.text-banner {
    .cmp-text {
        background-color: $color-shade-1;
        padding: 1.875rem 2.188rem 2.5rem 2.188rem;

        @media only screen and (max-width: $mobile) {
            padding: 2.5rem 1.563rem 1.25rem 1.563rem;
        }
    }

    table {
        margin-block: 0.75rem;
    }
    caption {
        padding-bottom: 1rem;
        font-size: 1.125rem;
        color: $color-text;
    }

    tr td {
        padding-block: 0.25rem;
        font-size: 0.875rem;
        font-family: $font-family;

        p {
            margin-block: 0;
        }
    }
}