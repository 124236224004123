.cmp-button__icon--footer-facebook,
.cmp-button__icon--footer-x-social,
.cmp-button__icon--footer-youtube,
.cmp-button__icon--footer-instagram,
.cmp-button__icon--facebook,
.cmp-button__icon--instagram,
.cmp-button__icon--youtube,
.cmp-button__icon--x-social {
    margin: 0 10px 0 0;
    display: inline-block;
    background-position: center;
    border-radius: 50%;
    background-repeat: no-repeat;
}

.cmp-button__icon--footer-facebook,
.cmp-button__icon--footer-x-social,
.cmp-button__icon--footer-youtube,
.cmp-button__icon--footer-instagram {
    width: 1.5rem;
    height: 1.5rem;
    background-color: $color-background;
    background-size: 16px;

}

.cmp-button__icon--facebook,
.cmp-button__icon--instagram,
.cmp-button__icon--youtube,
.cmp-button__icon--x-social {
    width: 2rem;
    height: 2rem;
    background-color: #e4212a;
    background-size: 26px;
}

.cmp-button__icon--facebook {
    background-image: url(data-url:resources/images/social-icons/facebook-white.svg);
}

.cmp-button__icon--x-social {
    background-image: url(data-url:resources/images/social-icons/x-social-white.svg);
}

.cmp-button__icon--youtube {
    background-image: url(data-url:resources/images/social-icons/youtube-white.svg);
}

.cmp-button__icon--instagram {
    background-image: url(data-url:resources/images/social-icons/instagram-white.svg);
}


.cmp-button__icon--footer-facebook {
    background-image: url(data-url:resources/images/social-icons/facebook-black.svg);
}

.cmp-button__icon--footer-x-social {
    background-image: url(data-url:resources/images/social-icons/x-social-black.svg);
}

.cmp-button__icon--footer-youtube {
    background-image: url(data-url:resources/images/social-icons/youtube-black.svg);
}

.cmp-button__icon--footer-instagram {
    background-image: url(data-url:resources/images/social-icons/instagram-black.svg);
}