.carousel--microcarousel {
    @include full-width;
    @include vertical-spacing(margin-top, small);
    @include desktop {
        width: unset;
        margin-left: unset;
    }

    .swiper-pagination {
        display: none;
    }

    .swiper__button-prev,
    .swiper__button-next {
        display: none;
    }

    .cmp-carousel {
        overflow: hidden;
        padding: spacing(0.5) spacing(3);

        @include desktop {
            position: relative;
            padding: spacing(0.5); // focus outline
        }

        &.overflown .cmp-carousel__content {
            justify-content: flex-start;
        }
    }

    .cmp-carousel__content {
        display: flex;
        justify-content: flex-start;
    }

    .swiper-initialized .cmp-carousel__content {
        justify-content: center;
    }

    .cmp-carousel__item {
        &:not(:last-child) {
            margin-inline-end: spacing(1);

            @include desktop {
                margin-inline-end: spacing(1.5);
            }
        }

        .adaptiveImage {
            margin: 0;
            overflow: hidden;
            border-radius: $radius-medium;

            &:focus-within {
                outline: 2px solid $black;
                outline-offset: 2px;
            }

            img {
                width: 104px;
                height: 104px;
                object-fit: cover;
            }
        }
    }

    .swiper-slide {
        width: auto;
    }

    .cmp-carousel__scroll {
        position: absolute;
        inset-block: 0;
        display: none;
        width: 80px;

        &--prev {
            inset-inline-start: 0;

            .cmp-carousel__scroll-button {
                @include navigation-arrow('left');

                inset-inline-start: 0;
            }

            .cmp-carousel__scroll-fade {
                rotate: 180deg;

                @include rtl {
                    rotate: 0deg;
                }
            }
        }

        &--next {
            inset-inline-end: 0;

            .cmp-carousel__scroll-fade {
                @include rtl {
                    rotate: 180deg;
                }
            }

            .cmp-carousel__scroll-button {
                @include navigation-arrow('right');

                inset-inline-end: 0;
            }
        }
    }

    .cmp-carousel__scroll-button {
        @include absolutelyCentered(true, false);

        z-index: layer(microcarousel, button);
    }

    .cmp-carousel__scroll-fade {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: layer(microcarousel, fade);
        background: linear-gradient(90deg, transparent, $gray01 35%);
    }

    .cmp-carousel.overflown {
        &--start .cmp-carousel__scroll--prev,
        &--end .cmp-carousel__scroll--next {
            @include desktop {
                display: block;
            }
        }
    }
}

@include authoringOnlyStyle {
    .carousel--microcarousel .cmp-carousel__content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: spacing(1);

        @include desktop {
            gap: spacing(1.5);
        }
    }
}
