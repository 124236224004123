#title-timeline {

    .cmp-title {
        margin-top: 1rem;
    }

    .cmp-title__text {
        font-size: 1.5rem;
        @media only screen and (max-width: $mobile) {
            font-size: 1rem;
            line-height: 1.5;
        }
    }

    .text {
        p {
            &:after {
                content: url('https://shared.coke.com/content/dam/wocc/us/en/keyboard_arrow_down_24px.svg');
                margin-top: -5px;
                display: block;
                height: 25px;
                width: 100%;
            }
        }
    }
}