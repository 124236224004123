.container-ribbon {
    padding: 0 0.625rem;
    height: 23.3rem;

    @media only screen and (max-width: $tablet) {
        margin-block: 0.8rem;
    }

    & > .cmp-container {
        background-color: $color-shade-1;
        height: 100%;

        .container {
            height: 20.55rem;

            //This is for the second container, wrapping the teaser.
            .cmp-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 2.5rem;
                height: 100%;
            }
        }
    }

    .title .cmp-title {
        background-color: $color-accent;
        color: white;
        text-transform: uppercase;
        text-align: center;
        line-height: 1.2;
        padding: 0.75rem 0;
        margin: 0;
    }


    .cmp-teaser {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        padding: 0 2.5rem;
    }

    .cmp-teaser__image {
        @media only screen and (max-width: $tablet) {
            width: 100%;
        }
    }

    .cmp-image__image {
        max-height: 10rem;
    }

    .cmp-teaser__action-container {
        padding: 1.25rem 0;
    }

    .cmp-teaser__action-link {
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: 700;
        color: $color-accent;

        &:after {
            content: "";
            @include arrow-right-color($color-accent);
            background-repeat: no-repeat;
            width: 0.6rem;
            height: 0.7rem;
            display: block;
            margin-left: 0.5rem;
            display: inline-block;
        }
    }
}