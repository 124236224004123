    .teaser-horizontal-two-buttons-bg-gray {
        @include teaser-horizontal-two-buttons; 

        .cmp-teaser {
            align-items: inherit;
        }
        .cmp-teaser__content {
            background-color: #e7e7e7; 
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .cmp-image,
        .cmp-image__image {
            height: 100%;
        }
    }