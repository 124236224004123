
//== Font

$font-family: 'Unity', "Gotham A", "Gotham B";
$font-family-title: 'Unity', "Gotham A", "Gotham B";
$font-size: 16px;
$font-height: 20px;


//== Color

$color-background: #fff;
$color-foreground: #000000;
$color-link: hsl(357, 79%, 50%);
$color-accent: hsl(357, 79%, 50%);
$color-accent-lighter: hsl(357, 79%, 59%);
$color-accent-darker: hsl(357, 79%, 40%);
$color-teaser-red: hsl(357, 78%, 51%);
$color-shade-1: #EFEFEF;
$color-shade-2: #E7E7E7;
$color-shade-3: #202020;
$color-shade-4: #000;

$error: #d7373f;
$error-darker: #be1e26;
$success: #268e6c;
$success-darker: #0d7553;

$color-text: #404040;
$color-text-darker: #343a40;
$color-text-inverted: $color-background;
$color-shadow: rgba(0, 0, 0, 0.16);
$color-transparent: rgba(0, 0, 0, 0);


//== Breakpoints

$mobile: 767px;
$tablet: 1023px;
$laptop: 1200px;