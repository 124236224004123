.cmp-separator {
}
.cmp-separator__horizontal-rule {
  border: none;
}
.horizontal-line {
  .cmp-separator__horizontal-rule {
    margin: 1.8rem 1.25rem;
    border-top: 3px solid #efefef;
  }
}
