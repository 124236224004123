.container {
    .teaser-vertical-white-link {
        border-right: 1px solid white;
        margin-top: 2rem;
        margin-bottom: 2rem;
        @media only screen and (max-width: $mobile) {
            border-right: 0;
            border-bottom: 1px solid white;
            margin-top: 0;
            margin-bottom: 0;
        }
        .cmp-teaser {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            padding: 1.875rem 0;
        }
        .cmp-image__image {
            max-width: 5.4rem;
        }
        .cmp-teaser__content {
            margin-top: 1.75rem;
        }

        .cmp-teaser__description {
            text-align: center;
            padding: 0 1.25rem;
            @media only screen and (min-width: $mobile) and (max-width: $tablet){
                padding: 0 3.5rem; 
            }
        }

        .cmp-teaser__action-link {
            text-transform: uppercase;
            font-size: 0.875rem;
            font-weight: 700;
            &:after {
                content: "";
                @include arrow-right-color(white);
                background-repeat: no-repeat;
                width: .6rem;
                height: .7rem;
                margin-left: .5rem;
                display: inline-block;
            }
        }
    }
    .teaser-vertical-white-link:last-child {
        border-right: 0;
        @media only screen and (max-width: $mobile) {
            border-bottom: 0;
        }
    }
}