.cmp-tabs {
}

.cmp-tabs__tablist {
  justify-content: space-evenly;
}

.cmp-tabs__tab {
  position: relative;
  height: 1.5rem;
  padding: 0;
  margin-block: .625rem;
  text-transform: uppercase;
  font-size: $font-size;
  border: none;
  outline: none;
  box-sizing: boder-box;

  &::after {
    display: inline-block;
    width: 100%;
    position: absolute;
    bottom: .125rem;
    left: 0px;
    border-bottom: .125rem solid black;
  }

  &:hover,
  &:focus {
    color: $color-text-darker;
    font-weight: bold;
    border: none;
    box-sizing: initial;

    &::after {
      content: "";
    }
  }
}
.cmp-tabs__tab--active {
  position: relative;
  color: $color-text-darker;
  font-weight: bold;
  &::after {
    content: "";
  }
}

.cmp-tabs__tab {
  &.cmp-tabs__tab--active  {
    box-sizing: initial;
    border-bottom: 0;
  }
}

.cmp-tabs__tabpanel {}
.cmp-tabs__tabpanel--active {
  padding: 0;
}