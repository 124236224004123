.cmp-embed {
  position: relative;

  .cmp-embed__youtube {

    &.cmp-embed--thumbnail {
      img {
        border-radius: 16px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        overflow: hidden;
      }

      .cmp-embed__load-button {
        span {
          display: block;
          position: absolute;
          inset: 0;
          background-color: transparent;
          cursor: pointer;
  
          &::before,
          &::after {
            content: "";
            display: block;
            position: absolute;
            width: 60px;
            height: 60px;
            border-radius: 30px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
  
          &::before {
            background-color:$color-accent;
          }
  
          &::after{
            rotate: 5deg;
            background-image: url('data-url:resources/images/play-button.svg');
            background-repeat: no-repeat;
            background-position: 50% 60%;
          }
        }
      }

      button.hidden,
      picture.hidden img {
        display: none;
      }
    }

    .cmp-embed__youtube-iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  .yv-video-portait & {
    @media only screen and (max-width: $mobile) {
      aspect-ratio: 9 / 16;
    }
  }
}