@import './variables_onexp';
@import './string-utils';

@mixin headerMobileBreakpoint {
    @media only screen and (max-width : #{$carouselDesktop - 1}) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (min-width : #{$carouselTablet}) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (min-width : #{$carouselDesktop}) {
        @content;
    }
}

@mixin expand-click-area($value) {
    &::after {
        content: '';
        display: block;
        position: absolute;
        inset: -$value;
    }
}

@mixin embedAspectRatio($value) {
    margin: 0;
    width: auto;
    height: auto;
    aspect-ratio: $value;
}

@function spacing($spacing) {
    @return $spacing * $spacingBase;
}

@function aspectRatio($type, $viewport: null) {
    @return map-get($aspectRatio, $type, $viewport);
}

// prettier-ignore
$marginSizesMobile: (
    xsmall: spacing(2),  // 16
    small: spacing(3),   // 24
    medium: spacing(4),  // 32
    large: spacing(7),   // 56
    xlarge: spacing(7),  // 56
);
// prettier-ignore
$marginSizesDesktop: (
    xsmall: spacing(3),  // 24
    small: spacing(4),   // 32
    medium: spacing(6),  // 48
    large: spacing(8),   // 64
    xlarge: spacing(15), // 120
);

@mixin vertical-spacing($parameter, $size) {
    #{$parameter}: map-get($marginSizesMobile, $size);

    @include desktop {
        #{$parameter}: map-get($marginSizesDesktop, $size);
    }
}

// e.g. `background-color: alphaColor(light, 12);`
@function alphaColor($type, $level) {
    @return map-get($alphaColors, $type, $level);
}

@mixin authoringOnlyStyle {
    .editor-panel.editor-panel-active,
    html[class*='aem-AuthorLayer-'] {
        @content;
    }
}

@mixin createLine($size, $align: before) {
    &::#{$align} {
        content: '';
        position: absolute;
        display: block;
        height: $size;
        inset-inline: 0;
        background: $gray02;
    }
}

@mixin gradient_position {
    content: '';
    position: absolute;
    inset: 0;
}

@mixin antiBoldShift($pseudoClass: after) {
    &::#{$pseudoClass} {
        content: attr(data-text);
        overflow: hidden;
        visibility: hidden;
        display: block;
        height: 0;
        font: inherit;
        font-weight: $fwBold;
    }
}

@mixin showMore($lines: 4) {
    transition: max-height $transition-ease-out;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
}

@mixin absolutelyCentered($vertical: true, $horizontal: true) {
    position: absolute;

    @if ($vertical and $horizontal) {
        top: 50%;
        left: 50%;
        translate: -50% -50%;
    } @else if ($vertical and not $horizontal) {
        top: 50%;
        translate: 0 -50%;
    } @else {
        left: 50%;
        translate: -50% 0;
    }
}

@mixin rtl {
    [dir='rtl'] & {
        @content;
    }
}

// to avoid including several icons each overwriting the previous one
// it's always the "up" one that will be appropriately rotated (left/right flipped on RTL)
@mixin navigation-arrow($dir: 'up', $size: 40px, $before-after: 'after') {

    display: flex;
    justify-content: center;
    align-items: center;
    width: $size;
    height: $size;
    border: none;
    border-radius: 50%;
    outline-offset: 2px;
    background-color: $white;
    color: $black;

    @include desktop {
        &:hover {
            background-color: $gray02;
        }
    }

    &:focus-visible {
        outline-color: $black;
    }

    &:active {
        background-color: $gray04;
    }

    &:is(:disabled, [disabled]) {
        color: $gray05;
        background-color: $gray03;
    }

    &:not(:disabled, [disabled]) {
        cursor: pointer;
    }

    &::#{$before-after} {
        color: inherit;
        font-weight: $fwBold;

        @if ($dir == 'right') {
            rotate: 90deg;

            @include rtl {
                rotate: 270deg;
            }
        } @else if ($dir == 'down') {
            rotate: 180deg;
        } @else if ($dir == 'left') {
            rotate: 270deg;

            @include rtl {
                rotate: 90deg;
            }
        }
    }
}
