.title-center {
    .cmp-title {
        text-align: center;
    }
}

.title-justify {
    .cmp-title {
        text-align: justify;
    }
}

.title-right {
    .cmp-title {
        text-align: right;
    }
}