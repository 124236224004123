// Fonts
$fontText: Noto-regional, Noto-latin, TCCC-UnityText, sans-serif;

$fwRegular: 400;
$fwBold: 700;

// Colors
$black: #000000;
$white: #ffffff;

$gray01: #eeeeee;
$gray02: #d5d5d5;
$gray03: #bdbdbd;
$gray04: #a5a5a5;
$gray05: #8b8b8b;
$gray06: #717171;
$gray07: #353535;

$alphaColors: (
    light: (
        12: rgba($white, 0.12),
        24: rgba($white, 0.24),
        32: rgba($white, 0.32),
        56: rgba($white, 0.56),
        80: rgba($white, 0.8),
    ),
    dark: (
        12: rgba($black, 0.12),
        24: rgba($black, 0.24),
        32: rgba($black, 0.32),
        56: rgba($black, 0.56),
        80: rgba($black, 0.8),
    ),
);

$red01: #f70000;
$red02: #de0000;

$blue: #1400ff;
$green: #73faa5;

$instagramElementColor: #f4f4f4;
$instagramLinkColor: #3897f0;
$instagramTextColor: #c9c8cd;

$standaloneKomotionColors: (
    controls: #2b333fb3,
    controlsLight: #73859f80,
    loadProgress: #bfc7d233,
    currentTimeTooltip: #ffffffcc,
    mouseDisplayTooltip: #000000cc,
);

// Spacings
$spacingBase: 8px;

// Responsive content width
$maxMobileWidth: 599px;
$maxDesktopWidth: 736px;
$tabletContent: 880px;
$desktopContent: 1120px;

// Media queries
$carouselMobile: 0;
$carouselTablet: 600px;
$carouselDesktop: 1200px;

// Aspect ratios
$aspectRatio: (
    heroNormal: (
        desktop: calc(1280 / 604),
        mobile: calc(327 / 539),
    ),
    heroCarousel: (
        desktop: calc(1280 / 604),
        mobile: calc(327 / 539),
    ),
    heroFullBleed: (
        desktop: calc(1440 / 620),
        mobile: calc(599 / 799),
    ),
    heroImage: (
        desktop: calc(1440 / 620),
        mobile: calc(599 / 449),
    ),
    recipeHero: (
        desktop: calc(1440 / 620),
        mobile: calc(375 / 375),
    ),
    promoHero: (
        desktop: calc(1280 / 604),
        mobile: calc(327 / 539),
    ),
    ngps: (
        desktop: calc(1440 / 810),
        mobile: calc(375 / 300),
    ),
    promoPrize: (
        desktop: calc(384 / 216),
        mobile: calc(279 / 157),
    ),
    digitalWallet: (
        desktop: calc(1440 / 810),
        mobile: calc(375 / 375),
    ),
    embed: (
        mobile: (
            '3-4': calc(3 / 4),
            '9-16': calc(9 / 16),
            '1-1': calc(1 / 1),
        ),
        desktop: (
            '4-3': calc(4 / 3),
            '16-9': calc(16 / 9),
            '1-1': calc(1 / 1),
        ),
    ),
    imageFullBleed: (
        '16-9': calc(16 / 9),
    ),
    komotion: (
        observable: calc(1 / 1),
        standalone: calc(16 / 9),
        generalCard: calc(1 / 1),
        contentCard: calc(4 / 3),
        campaignCard: calc(5 / 4),
        hero: (
            mobile: calc(9 / 16),
            desktop: calc(16 / 9),
        ),
    ),
    searchResult: (
        '16-9': calc(16 / 9),
    ),
);

// Secondary Navigation
$secondary-navigation-height-mobile: 80px;
$secondary-navigation-height-desktop: 96px;

// Transitions
$transition-standard: 0.3s ease-in-out;
$transition-ease-out: 0.2s ease-out;

// Box Shadows
$shadow-soft: 0 14px 16px rgba($black, 0.04);
$shadow-medium: 0 14px 16px rgba($black, 0.06);
$shadow-bold: 0 14px 24px rgba($black, 0.06);

// Border Radius
$radius-xsmall: 4px;
$radius-small: 8px;
$radius-medium: 16px;
$radius-large: 48px;

// Global variables
:root {
    --onexp-white: #{$white};
    --onexp-black: #{$black};
}
