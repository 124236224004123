.teaser-horizontal-stretched {
    .cmp-teaser {
        display: flex;
        flex-direction: row-reverse;
        padding: 4.688rem 0;
        max-width: 70%;
        margin: 0 auto;
        @media only screen and (max-width: $tablet) {
            flex-direction: column-reverse;
            padding: 1.875rem 0;
            max-width: 90%;
            margin: 0 auto;
        }
    }

    .cmp-teaser__content {
        display: grid;
        flex-wrap: wrap;
        color: white;
        padding-left: 5rem;
        @media only screen and (max-width: $tablet) {
            display: flex;
            justify-content: center;
            padding-left: 0;
            text-align: center;
            max-width: 31rem;
            margin: 0 auto;
        }
    }

    .cmp-teaser__title {
        flex-basis: 80%;
        font-size: 2.25rem;
        text-transform: uppercase;
        @media only screen and (max-width: $tablet) {
            flex-basis: 100%;
            line-height: initial;
            margin-top: 0.938rem;
            font-size: 1.125rem;
        }
    }

    .cmp-teaser__description {
        max-width: 90%;
        margin-top: 0.8rem;
        font-weight: 300;
        @media only screen and (max-width: $tablet) {
            max-width: 100%;
            margin-top: 0;
        }
        p {
            font-size: 0.875rem;
            line-height: 1.313rem;
        }
    }

    .cmp-teaser__image {
        max-width: 5.4rem;
        @media only screen and (max-width: $tablet) {
            margin: 0 auto;
        }
    }

    .cmp-teaser__action-link {
        background: #fff;
        color: #f40000;
        @include cmp-button;
    }

    .cmp-teaser__action-container {
        display: flex;
        grid-row-start: 1;
        grid-row-end: 3;
        grid-column-start: 2;
        grid-column-end: 3;
        align-self: center;
        @media only screen and (max-width: $tablet) {
            padding: 1.25rem;
        }
    }
}