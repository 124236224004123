
//== Variables

@import 'site/variables.scss';
@import 'site/grid.scss';

//== Site global

@import 'npm:normalize.css';
@import 'site/fonts.scss';
@import 'site/base.scss';

//== Core components

@import 'components/text/_text.scss';
@import 'components/text/_text_colors.scss';
@import 'components/text/_text_align.scss';
@import 'components/text/_text_table_header.scss';
@import 'components/text/_text_hero.scss';


@import 'components/title/_title.scss';
@import 'components/title/_title_styles.scss';
@import 'components/title/_title_colors.scss';
@import 'components/title/_title_align.scss';

@import 'components/image/_image.scss';
@import 'components/image/_image_caption.scss';
@import 'components/image/_image_standard.scss';

@import 'components/button/_button.scss';
@import 'components/button/button_plain.scss';
@import 'components/button/_button_social.scss';
@import 'components/button/_button_colors.scss';


@import 'components/separator/_separator.scss';
@import 'components/separator/_separator_small.scss';
@import 'components/separator/_separator_standard.scss';

@import 'components/progressbar/_progressbar.scss';



@import 'components/teaser/_teaser_header.scss';
@import 'components/teaser/_teaser_hero.scss';
@import 'components/teaser/_teaser_horizontal_stretched.scss';
@import 'components/teaser/_teaser_horizontal_two_buttons.scss';
@import 'components/teaser/_teaser_horizontal_two_buttons_bg_gray.scss';
@import 'components/teaser/_teaser_horizontal_red_bg.scss';
@import 'components/teaser/_teaser_horizontal_red_bg_center.scss';
@import 'components/teaser/_teaser_horizontal_image_right.scss';
@import 'components/teaser/_teaser_table.scss';
@import 'components/teaser/_teaser_text_bold.scss';
@import 'components/teaser/_teaser_text_timeline.scss';
@import 'components/teaser/_teaser_vertical_centered_gray.scss';
@import 'components/teaser/_teaser_vertical_left_aligned.scss';
@import 'components/teaser/_teaser_vertical_white_link.scss';
@import 'components/teaser/_teaser_vertical_white_button_fill.scss';
@import 'components/teaser/_teaser_horizontal_icon.scss';
@import 'components/teaser/_teaser_vertical_icon.scss';
@import 'components/teaser/teaser_pop-up.scss';

@import 'components/download/_download.scss';

@import 'components/embed/_embedThirdParty.scss';

//== Lists and Navigation components

@import 'components/list/_list.scss';

@import 'components/navigation/_navigation.scss';

@import 'components/languagenavigation/_languagenavigation.scss';

@import 'components/breadcrumb/_breadcrumb.scss';
@import 'components/breadcrumb/_breadcrumb_standard.scss';

@import 'components/search/_search.scss';

//== Container components

@import 'components/container/_container.scss';
@import 'components/container/_container_homepage_images.scss';
@import 'components/container/_container_content.scss';
@import 'components/container/_container_dark_theme.scss';
@import 'components/container/_container_main.scss';
@import 'components/container/_container_ribbon.scss';
@import 'components/container/_container_groups_grid.scss';
@import 'components/container/_container_title_timeline.scss';
@import 'components/container/_container_title_header.scss';
@import 'components/container/_container_hero_video.scss';
@import 'components/container/_container_floating_fruits.scss';


@import 'components/carousel/_carousel.scss';
@import 'components/carousel/carousel-card.scss';
@import 'components/carousel/carousel-hero.scss';
@import 'components/carousel/carousel-microcarousel.scss';


@import 'components/tabs/_tabs.scss';
@import 'components/tabs/_tabs_vertical.scss';

@import 'components/accordion/_accordion.scss';

//== Fragments (CF + XF) components

@import 'components/contentfragment/_contentfragment.scss';
@import 'components/contentfragmentlist/_contentfragmentlist.scss';

@import 'components/experiencefragment/_experiencefragment.scss';
@import 'components/experiencefragment/_experiencefragment_footer.scss';
@import 'components/experiencefragment/_experiencefragment_header.scss';

//== Form components

@import 'components/form/_form.scss';
@import 'components/form-button/_form-button.scss';
@import 'components/form-text/_form-text.scss';
@import 'components/form-options/_form-options.scss';

//== Embed components

@import 'components/embed/_embed.scss';

@import 'components/pdfviewer/_pdfviewer.scss';

@import 'components/socialmediasharing/_social_media_sharing.scss';

@import 'components/embed/_embed_youtube.scss';