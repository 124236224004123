.cmp-container {}

.center-on-screen {
    > .cmp-container {
        max-width: 84%;
        margin-left: auto !important;
        margin-right: auto;
        float: none !important;

        @media only screen and (max-width: $tablet) {
            max-width: 90%;
        }
    }
}

//If center-on-screen is used on explore-article type of page
//it would match the properties of the /explore-bottle-works page for two images.
.explore-article {
    #lead-image-left {
        padding: 1.875rem 0.5rem 0 0.5rem;

        @media only screen and (max-width: $mobile) {
                padding: 1.875rem 0.125rem 0 0.125rem;
            }
    }

    #lead-image-right {
        padding: 1.875rem 0.625rem 0 0.625rem;

        @media only screen and (max-width: $mobile) {
            padding: 1.875rem 0.125rem 0 0.188rem;
        }
    }

    .center-on-screen {
        > .cmp-container {
            max-width: 67%;

            @media only screen and (max-width: $tablet) {
                max-width: 84%;
            }
        }
    }
}

.container-banner {
    > .cmp-container {
        background-color: $color-shade-1;
        padding: 1.875rem 2.188rem 2.5rem 2.188rem;

        @media only screen and (max-width: $mobile) {
            padding: 2.5rem 1.563rem 1.25rem 1.563rem;
        }
    }
}

.card-ticket-types-table  {
    > .cmp-container {
        display: flex;

        @media only screen and (max-width: $tablet) {
            display: block;
        }

        > div {
            display: inherit;
        }
    }
}

.card-ticket-type-right {
    > .cmp-container {
        height: 100%;
        .aem-Grid {
            display: flex;
            flex-wrap: wrap;
            height: 100%;
        }
    }

     .text {
        &:nth-of-type(1) {
            background: $color-teaser-red;
            color: #ffffff;
            padding-top: 2.5rem;
            padding-left: 1.8rem;
            padding-right: 1.8rem;
            padding-bottom: 1.6rem;
            
            @media only screen and (max-width: $tablet) {
                padding: 1.5rem 0.8rem 1.6rem;
            }
        
            table {
                color: #ffffff;
                width: 100%;
                max-width: 300px;
             
                td {
                    font-size: 1.125rem;
                    padding: .25rem 0.2rem; 

                    &:nth-of-type(1) {
                        width: 50px;
                    }

                    &:nth-of-type(4) {
                        width: 40px;
                        text-align: center;
                    }
                }
            }
        }

        &:nth-of-type(2) {
            background: #404040;
            padding-top: 2.5rem;
            padding-left: 2.2rem;
            padding-right: 1.5rem;
            padding-bottom: .9rem;

            p, a {
                color: #ffffff;
            }
        }
    }
}

#center-bg-image {
    background-position: center;
}

#container-404 {
    position: relative;
    @media only screen and (max-width: $tablet) {
        text-align: center;
    }

    .container {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

}

#teasers-row-3 {
    @media only screen and (min-width: $tablet) {
        max-width: 70%;
        margin: 0 auto;
    }
}

.d-flex {
    > .cmp-container {
        display: flex;

        > div {
            display: inherit;
        }
    }
}

.d-flex-center {
    > .cmp-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.d-flex-horizontal-center {
    > .cmp-container {
        > div {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
    }
}

