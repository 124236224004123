.container-homepage-images {
    > .cmp-container {
        display: grid;
        justify-content: space-around;
        grid-template-columns: 50% 50%;
        gap: 1rem;
        @media only screen and (min-width: $mobile) {
           grid-template-columns: repeat(4, 20%); 
        }
    }
}