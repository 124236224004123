.teaser-pop-up {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 3;
    background-color: hsla(0, 0%, 0%, 0.788);

    .cmp-teaser {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        width: 327px;
        min-height: 282px;
        border: 1px solid $color-accent;
        border-radius: 16px;

        @media only screen and (min-width: calc($mobile)) {
            width: 540px;
            min-height: 330px;
        }
    }

    .cmp-teaser__image {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $color-accent;
        padding: 16px 24px;
        border-radius: 15px 15px 0 0;

        & img {
            height: 32px;;
        }

        & .close-popup {
            cursor: pointer;
            font-size: 1.5rem;
            color:white;
        }
    }
    
    .cmp-teaser__content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 10px;
        padding-block: 16px;
        background-color: white;
        border-radius: 0 0 15px 15px;

        .cmp-teaser__description {
            font-size: 1.125rem;
            line-height: 1.688rem;
            font-weight: 700;
        }

        .cmp-teaser__action-container {
            display: flex;
            gap: 16px;

            .cmp-teaser__action-link {
                background-color: $color-accent;
                font-size: 0.875rem;
                line-height: 1.138rem;
                font-weight: 700;
                border-radius: 10px;
                padding: 10px 16px;
            }
        }
    }

    .aem-AuthorLayer-Edit &,
    &.d-none {
        display: none;
    }
}

.pop-up-open {
    overflow: hidden;
}