.teaser-horizontal-image-right {
  @include teaser-horizontal-two-buttons;

  .cmp-teaser {
      flex-direction: row;
      align-items: inherit;

      @media only screen and (max-width: $tablet) {
        flex-direction: column-reverse;
      }
  }

  .cmp-teaser__image {
    flex: 0 0 42%;
  }

  .cmp-teaser__description {
    margin-right: 1rem;
    max-width: 37.5rem;
  }
  .cmp-teaser__content {
      background-color: #e7e7e7;
      display: flex;
      flex: 0 0 58%;
      flex-direction: column;
      justify-content: center;
      padding: 1.875rem 1.875rem 1.875rem 2.875rem;
      box-sizing: border-box;
  }
}