[class*="embed-top"],
[class*="embed-bottom"]{
    .cmp-embed__video__container {
        position: absolute;
        width: 200px;
        z-index: 999;

        @media only screen and (max-width: $mobile) {
            width: 100px;
        }

        video {
            width: 200px;

            @media only screen and (max-width: $mobile) {
                width: 100px;
            }
        }
    }
}

.embed-bottom-left {
    .cmp-embed__video__container {
        left: 50px;
        bottom: -100px;

        @media only screen and (max-width: $mobile) {
            left: 10px;
            bottom: -50px;
        }
    }
}

.embed-bottom-right {
    .cmp-embed__video__container {
        right: 50px;
        bottom: -100px;

        @media only screen and (max-width: $mobile) {
            right: 10px;
            bottom: -50px;
        }
    }
}

.embed-top-left {
    .cmp-embed__video__container {
        left: 50px;
        top: -100px;

        @media only screen and (max-width: $mobile) {
            left: 10px;
            top: -50px;
        }
    }
}

.embed-top-right {
    .cmp-embed__video__container {
        right: 50px;
        top: -100px;

        @media only screen and (max-width: $mobile) {
            right: 10px;
            top: 50px;
        }
    }
}