@font-face {
    font-family: "Unity";
    src: url("resources/fonts/Unity/TCCC-UnityText-Light.woff") format("woff");
    font-weight: 300;
  }
  @font-face {
    font-family: "Unity";
    src: url("resources/fonts/Unity/TCCC-UnityText-Regular.woff") format("woff");
    font-weight: 400;
  }
  @font-face {
    font-family: "Unity";
    src: url("resources/fonts/Unity/TCCC-UnityText-Medium.woff") format("woff");
    font-weight: 500;
  }
  @font-face {
    font-family: "Unity";
    src: url("resources/fonts/Unity/TCCC-UnityText-Bold.woff") format("woff");
    font-weight: 700;
  }
  @font-face {
    font-family: "Unity";
    src: url("resources/fonts/Unity/TCCC-UnityText-Black.woff") format("woff");
    font-weight: 900;
  }
  @font-face {
    font-family: "UnityCondensed";
    src: url("resources/fonts/Unity/TCCC-UnityCondensed-Medium.woff") format("woff");
    font-weight: 500;
  }
  @font-face {
    font-family: "UnityCondensed";
    src: url("resources/fonts/Unity/TCCC-UnityCondensed-Bold.woff") format("woff");
    font-weight: 700;
  }
  @font-face {
    font-family: "UnityHeadline";
    src: url("resources/fonts/Unity/TCCC-UnityHeadline-Light.woff") format("woff");
    font-weight: 300;
  }
  @font-face {
    font-family: "UnityHeadline";
    src: url("resources/fonts/Unity/TCCC-UnityHeadline-Regular.woff") format("woff");
    font-weight: 400;
  }
  @font-face {
    font-family: "UnityHeadline";
    src: url("resources/fonts/Unity/TCCC-UnityHeadline-Medium.woff") format("woff");
    font-weight: 500;
  }
  @font-face {
    font-family: "UnityHeadline";
    src: url("resources/fonts/Unity/TCCC-UnityHeadline-Bold.woff") format("woff");
    font-weight: 700;
  }
  @font-face {
    font-family: "UnityHeadline";
    src: url("resources/fonts/Unity/TCCC-UnityHeadline-Black.woff") format("woff");
    font-weight: 900;
        }