.cmp-accordion {
  margin: 0.5em;

  @media only screen and (max-width: $tablet) {
    margin: 0;
  }
}

.cmp-accordion__item {
  margin-bottom: 10px;

}
.cmp-accordion__header {
}

.cmp-accordion__button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  background-color: #EFEFF1;
  border: none;
  outline: none;
  cursor: pointer;
  .cmp-accordion__icon {
    &:after {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTAiIGN5PSIxMCIgcj0iMTAiIGZpbGw9IiNFMzIxMkIiLz4KPHBhdGggZD0iTTEwLjUgMTRMNi42MDI4OSA3LjI1TDE0LjM5NzEgNy4yNUwxMC41IDE0WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
      background-repeat: no-repeat;
      background-position: center;
      transition: transform 200ms ease-in-out;
    }
  }
}

.cmp-accordion__button--expanded {
  outline: none;
  .cmp-accordion__icon {
    &:after {
      transform: rotate(-180deg);
      transition: transform 200ms ease-in-out;
    }
  }
}

.cmp-accordion__title {
  color: $color-text;
  font-weight: 500;
  font-size: $font-size;
}

.cmp-accordion__icon {
  float: right;
}

.cmp-accordion__panel {
  padding: 1.25rem 6rem;

  @media only screen and (max-width: $tablet){
    padding: 1.25rem 3.5rem;
  }

  @media only screen and (max-width: $mobile){
    padding: 1.25rem;
  }
}

.cmp-accordion__panel--expanded {
}

.cmp-accordion__panel--hidden {
}
