.teaser-vertical-left-aligned {
  .cmp-teaser {
    position: initial;
    margin: 2.5em 0.5em 0.5em 0.5em;
    display: flex;
    flex-direction: column-reverse;
  }
  .cmp-teaser__image {
    position: relative;
    padding-bottom: 87.77%;
    overflow: hidden;
    & .cmp-image {
      margin: 0;
    }
    & img {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; 
    }
  }
  .cmp-teaser__content {
    position: initial;
    top: 0;
    left: 0;
    padding: 1rem 0 0 0;
    transform: none;
  }
  .cmp-teaser__pretitle {
    margin: 0;
    color: $color-shade-3;
    font-weight: 300;
    font-size: 0.625em;
    line-height: 1.125rem;
    letter-spacing: 0.025rem;
    text-align: start;
    text-transform: uppercase;
  }
  .cmp-teaser__title {
    color: #202020;
    font-size: 1.5rem;
    line-height: 2.25rem;
    text-align: start;
    text-decoration: none;
  }
  .cmp-teaser__title-link {
    color: $color-text;
    font-weight: 400;
    text-decoration: none;
  }
  .cmp-teaser__description {
    color: #606060;
    font-size: .875rem;
    line-height: 1.75em;
    text-align: start;
  }
  .cmp-teaser__action-container {
    display: flex;
    justify-content: start;
    margin: 1rem 0;
    padding: 0.125rem;
  }

  .cmp-teaser__action-link {
    font-size: .87rem;
    color: $color-accent;
    padding: 0;
    border: none;
    font-weight: 700;
    text-transform: uppercase;

    &:after {
      content: "";
      @include arrow-right-color($color-accent);
      background-repeat: no-repeat;
      width: .6rem;
      height: .7rem;
      margin-left: .5rem;
      display: inline-block;
      }
    }
}
