.hero-text-bottom,
.hero-text-center,
.hero-text-top {
  position: absolute;
  z-index: 1;
  * {
    color: #fff;
  }
}

.hero-text-top {
  top: 20px;
  * {
    font-size: 16px;
    line-height: 1;

    @media only screen and (min-width: $tablet) {
      font-size: 40px;
      line-height: 1.1;
    }
  }
}

.hero-text-center {
  top: 50%;
  transform: translateY(-50%);
   p {
    font-size: 12vw;

    @media only screen and (min-width: $tablet) {
      font-size: 10vw;
    }
   }
}

.hero-text-bottom {
  bottom: 20px;
  * {
    font-size: 16px;

    @media only screen and (min-width: $tablet) {
      font-size: 40px;
    }
  }
  // we can use it later
  // .cmp-text {
  //   display: grid;
  //   grid-template-columns: repeat(3, 1fr);
  // }
}