.teaser-horizontal-icon {
    .cmp-teaser {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        gap: 5px;
        padding-left: 4.375rem;
        border-right: 0.5px solid $color-shade-3;
        margin-block: 1rem;

        @media only screen and (max-width: $mobile) {
            border: 0;
        }

        @media only screen and (max-width: $tablet) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    .cmp-teaser__title {
        font-size: 1.25rem;
        line-height: 1.2;
        font-weight: 700;

        @media only screen and (max-width: $mobile) {
            font-size: 1.125rem;
        }
    }

    .cmp-image__image {
        min-width: 1.5rem;
        width: auto;
        max-width: 100%;
    }

    .cmp-teaser__description {
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 1.5rem;

        p {
            font-size: 0.875rem;
            line-height: 1.275rem;
            margin-top: 0.5rem;
            margin-bottom: 0;
        }
    }
}

.teaser-horizontal-icon:last-child .cmp-teaser {
    border-right: 0;
}