$column-count: 12 !default;
$mobileMargin: spacing(3);
$tabletMargin: spacing(8);

@function find_column_width($columns: $column-count) {
    $column-width: calc(100% / $columns);
    @return $column-width;
}

@function columns_width($columns, $all-columns: $column-count) {
    $column-width: calc(100% / $all-columns * $columns);
    @return $column-width;
}

@function desktop_margin_width() {
    @return calc((100vw - $desktopContent - var(--scrollWidth)) / 2);
}

@mixin full-width($includeTablet: false) {
    margin-left: -$mobileMargin;
    width: calc(100vw - var(--scrollWidth));

    @if ($includeTablet) {
        @include tablet {
            margin-left: -$tabletMargin;
        }
    }

    @include desktop {
        margin-left: calc(($desktopContent - 100vw + var(--scrollWidth)) / 2);
    }
}

@mixin max-width($includeTablet: false, $customSize: 1440px) {
    $fullWidth: calc(100vw - var(--scrollWidth));
    $maxWidth: min($fullWidth, $customSize);
    margin-left: -$mobileMargin;
    width: $maxWidth;

    @if ($includeTablet) {
        @include tablet {
            margin-left: -$tabletMargin;
        }
    }

    @include desktop {
        margin-left: calc(($desktopContent - $maxWidth) / 2);
    }
}

@mixin grid-container($includeTablet: false) {
    max-width: none;
    margin-inline: $mobileMargin;

    @if ($includeTablet) {
        @include tablet {
            margin-inline: $tabletMargin;
        }
    }

    @include desktop {
        max-width: $desktopContent;
        margin-inline: auto;
    }
}

@mixin grid($includeTablet: false) {
    display: flex;
    flex-wrap: wrap;
    margin-inline: spacing(-1);

    @if ($includeTablet) {
        @include tablet {
            margin-inline: spacing(-1.5);
        }
    }

    @include desktop {
        margin-inline: spacing(-2);
    }
}

@mixin column($columns: 0, $md: null, $lg: null) {
    $mobileWidth: find_column_width(4);
    $tabletWidth: find_column_width(6);
    $desktopWidth: find_column_width(12);
    $flex-basis: $mobileWidth * $columns;
    padding-inline: spacing(1);

    @if ($columns == 0) {
        flex: 1 0 0;
        width: auto;
    } @else {
        flex: 0 0 $flex-basis;
        min-width: $flex-basis;
    }

    @if ($md != null) {
        @media screen and (min-width: $carouselTablet) {
            padding-inline: spacing(1.5);
            $flex-basis: $tabletWidth * $md;
            @if ($md == 0) {
                flex: 1 0 0;
                width: auto;
            } @else {
                flex: 0 0 $flex-basis;
                min-width: $flex-basis;
            }
        }
    }

    @if ($lg != null) {
        @media screen and (min-width: $carouselDesktop) {
            padding-inline: spacing(2);
            $flex-basis: $desktopWidth * $lg;
            @if ($lg == 0) {
                flex: 1 0 0;
                width: auto;
            } @else {
                flex: 0 0 $flex-basis;
                min-width: $flex-basis;
            }
        }
    }
}
