@mixin cmp-button {
    font-size: .75rem;
    border-radius: 0.5rem;
    padding: 0.75rem 2.5rem;
    display: inline-block;
    font-weight: 700;
    text-transform: uppercase;
}


.red-button {
    .cmp-button {
        background: $color-teaser-red;
        color: #fff;
        @include cmp-button;
    }
}

.white-button {
    .cmp-button {
        background: #fff;
        color: #f40000;
        @include cmp-button;
    }
}

.black-button {
    .cmp-button {
        background: #000;
        color: #fff;
        @include cmp-button;
    }
}