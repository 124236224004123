.carousel--card-primary,
.carousel--card-secondary {
    @include vertical-spacing(margin-top, medium);
    @include desktop {
        width: auto;
        margin-inline: auto;
    }

    .teaser a:focus-visible {
        outline-offset: -2px;
    }
}
