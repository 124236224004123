.teaser-header {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 6;
    margin-top: -1.3rem;
    width: 35rem;
    margin-left: auto;
    display: none;
    
    .cmp-teaser {
        position: relative;
    }

    .cmp-teaser__description {
        color: white;
        font-size: 1.5rem;
        font-weight: 700;
        p {
            margin-block: 0.5rem;
        }
    }

    .cmp-teaser__content {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .cmp-teaser__action-container {
        margin-top: 1.2rem;
    }

    .cmp-teaser__action-link {
        background: #f40000;
        color: #fff;
        @include cmp-button;
        display: inline-block;
    }
}