.groups-grid {
    & > .cmp-container {
        display: grid;
        grid-template-columns: 0.5fr 0.1fr 0.2fr 0.2fr;
        grid-template-areas:
        'back back banner banner'
        'header header banner banner'
        'description description banner banner'
        'info info banner banner'
        'info-left info-right info-right info-right';
        column-gap: 2.5rem;

        @media only screen and (max-width: $tablet) {
            grid-template-columns: 1fr;
            column-gap: 0;
            grid-template-areas:
            'back'
            'header'
            'description'
            'info'
            'info-left'
            'info-right'
            'banner';
        }
    }

    .cmp-teaser__title {
        line-height: 1.3;
    }

    .button {
        grid-area: back;
    }

    .title {
        grid-area: header;

        .cmp-title {
            margin-top: 1.25rem;
            margin-bottom: 1.25rem;
        }

        .cmp-title__text {
            font-size: 2.25rem;

            @media only screen and (max-width: $mobile) {
                font-size: 1.5rem;
            }
        }
    }

    .text {
        grid-area: description;
        margin-bottom: 1rem;

        p {
            font-size: 0.875rem;
            line-height: 1.4;
        }
    }

    .image {
        grid-area: banner;
        margin-bottom: 1rem;
        @media only screen and (max-width: $tablet) {
            display: none;
        }
    }

    .button, .title, .text {
        @media only screen and (max-width: $mobile) {
            padding-inline: 1.6rem;
        }
    }

    .teaser-groups-banner {
        grid-area: banner;

        .cmp-teaser {
            height: 100%;
        }

        .cmp-teaser__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-color: $color-accent;
            box-sizing: border-box;
            padding: 2rem 2.5rem;

            @media only screen and (max-width: $tablet) {
                padding: 4rem 1.6rem;
            }
        }

        .cmp-teaser__title {
            margin-bottom: 1.875rem;
            max-width: 22.5rem;
            font-size: 2.25rem;
            line-height: 1.2;
            color: white;

            @media only screen and (max-width: $tablet) {
                max-width: 100%;
                text-align: left;
            }
        }

        .cmp-teaser__action-container {
            margin: 0;

            .cmp-teaser__action-link {
                display: inline-block;
                background: #fff;
                color: $color-accent;
                border: none;
                border-radius: 0.5rem;
                padding: 0.625rem 1.25rem;
                font-size: .75rem;
                font-weight: 500;
            }
        }

        .cmp-teaser__action-container {
            @media only screen and (max-width: $tablet) {
                    justify-content: flex-start;
            }
        }
    }

    .teaser-groups-info {
        grid-area: info;

        .cmp-teaser {
            background-color: $color-shade-1;
            padding-block: 1.25rem;

        }
        .cmp-teaser__content {
            padding-inline: 1.6rem;

            h5, p {
                font-size: 0.875rem;
                line-height: 1.4;
                color: $color-text;
            }
        }

        .cmp-teaser__description {
            max-width: 34.4rem;
            padding-bottom: 0.5rem;

            @media only screen and (max-width: $tablet) {
                text-align: left;
            }
        }

        .cmp-teaser__action-container {
            margin: 0;

            @media only screen and (max-width: $tablet) {
                align-items: flex-start;
            }

            .cmp-teaser__action-link {
                display: inline-block;
                background-color: $color-accent;
                border-radius: 0.5rem;
                padding: 0.75rem 1.813rem 0.75rem 1.813rem;
                font-size: 0.75rem;
            }

            @media only screen and (max-width: $tablet) {
                margin-bottom: 0;
            }
        }
    }

    .teaser-groups-info-left,
    .teaser-groups-info-right {
        margin-top: 2rem;

        .cmp-teaser {
            background-color: $color-background;
            border-top: 5px $color-teaser-red solid;
            padding-block: 1.25rem;

            @media only screen and (max-width: $mobile) {
                margin-inline: 1.6rem;
            }
        }
        .cmp-teaser__content {
            padding-inline: 1.6rem;

            @media only screen and (max-width: $tablet) {
                padding-inline: 0;
            }
            h5, p {
                font-size: 0.875rem;
                line-height: 1.4;
                color: $color-text;
            }
        }

        .cmp-teaser__description {
            max-width: 34.4rem;
            padding-bottom: 0.5rem;

            @media only screen and (max-width: $tablet) {
                text-align: left;
            }
        }

        .cmp-teaser__action-container {
            margin: 0;

            @media only screen and (max-width: $tablet) {
                align-items: flex-start;
            }

            .cmp-teaser__action-link {
                display: inline-block;
                background-color: $color-accent;
                border-radius: 0.5rem;
                padding: 0.75rem 1.813rem 0.75rem 1.813rem;
                font-size: 0.75rem;
            }

            @media only screen and (max-width: $tablet) {
                margin-bottom: 0;
            }
        }
    }

    .teaser-groups-info-left {
        grid-area: info-left;
    }

    .teaser-groups-info-right {
        grid-area: info-right;
    }
}