.title-white {
    .cmp-title__text {
        color: #ffffff;
    }
    .cmp-title__link {
        color: #ffffff;
        &:hover,
        &:focus {
            color: #eceff1;
            text-decoration-color: #eceff1;
        }
    }
}

.title-red {
    .cmp-title__text {
        color: $color-accent;
    }
}