.teaser-horizontal-red-center {
    @include teaser-horizontal-two-buttons;

    .cmp-teaser {
        align-items: inherit;
    }

    .cmp-teaser__image {
        flex: 0 0 50%;

        @media only screen and (max-width: $tablet) {
            flex: 0 0 100%;
        }

        & img {
            object-fit: fill;
        }
    }

    .cmp-teaser__content {
        background-color: $color-teaser-red;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        flex: 0 0 50%;

        @media only screen and (max-width: $tablet) {
            flex: 0 0 100%;
        }

        .cmp-teaser__title {
            font-size: 1.5rem;
            font-weight: 500;
        }

        .cmp-teaser__title,
        .cmp-teaser__description {
            color: white;
            text-align: center;
        }
    }

    .cmp-teaser__action-container {
        width: 90%;
        justify-content: center;

        & .cmp-teaser__action-link {
            text-align: center;

            @media only screen and (max-width: $tablet) {
                width: 100%;
                box-sizing: border-box;
            }
        }

        & .cmp-teaser__action-link:nth-child(1) {
            background-color: white;
            color: $color-teaser-red;
        }
    }
}