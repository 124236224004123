@mixin teaser-horizontal-two-buttons {
  .cmp-teaser {
    position: static;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    @media only screen and (max-width: $tablet) {
      flex-direction: column-reverse;
      align-items: inherit;
    }
  }
  .cmp-teaser__image {
    flex: 3;
    & img {
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }
  .cmp-teaser__content {
    position: static;
    top: 0;
    left: 0;
    flex: 2;
    margin: 0;
    padding: 1rem 1rem 1rem 4rem;
    transform: none;
    @media only screen and (max-width: $tablet) {
      padding: 0;
    }
  }
  .cmp-teaser__pretitle {
    color: $color-shade-3;
    font-weight: 300;
    font-size: 0.625em;
    line-height: 1.125rem;
    letter-spacing: 0.025rem;
    text-align: start;
    text-transform: uppercase;
  }
  .cmp-teaser__title {
    margin-top: 0.625rem;
    margin-bottom: 1.1875rem;
    color: #212529;
    font-size: 2rem;
    line-height: 2.25rem;
    text-align: start;
    text-decoration: none;
    @media only screen and (max-width: $tablet) {
      text-align: center;
      margin-top: 1.5rem;
    }
   
  }
  .cmp-teaser__title-link {
    color: $color-text;
    font-weight: 400;
    line-height: 2.25rem;
    text-decoration: none;
  }
  .cmp-teaser__description {
    color: $color-text;
    font-weight: 300;
    font-size: 1em;
    line-height: 1.75em;
    text-align: start;
    @media only screen and (max-width: $tablet) {
      text-align: center;
    }
  }
  .cmp-teaser__action-container {
    display: flex;
    gap: 10%;
    margin: 1rem 0;
    padding: 0.125rem;
    a {
      margin-bottom: 1rem;
    }
    @media only screen and (max-width: $tablet) {
      flex-direction: column;
      align-items: center;
    }

    @media only screen and (min-width: $tablet) and (max-width: $laptop) {
      flex-direction: column;
      align-items: flex-start;
    }
    .cmp-teaser__action-link:nth-child(1) {
          background: $color-teaser-red;
          color: #fff;
          @include cmp-button;
          @media only screen and (max-width: $tablet) {
              margin-bottom: 1rem;
          }
    }

    .cmp-teaser__action-link:nth-child(2) {
          background: #000;
          color: #fff;
          @include cmp-button;
    }
  }
}

.teaser-horizontal-two-buttons {
    @include teaser-horizontal-two-buttons;

    .event-page & {
      .cmp-teaser {
        padding-block: 0.625rem;

        @media screen and (max-width: $tablet){
          padding-block: 1.25rem;
        }
      }

      .cmp-teaser__image {
        flex: 1.5;
      }

      .cmp-teaser__content {
        padding: 0 2.2rem;

        @media only screen and (max-width: $tablet) {
          padding: 0;
        }

        b, strong {
          font-weight: bold;
        }

        .cmp-teaser__title,
        .cmp-teaser__description {
          color: $color-text;
        }
      }

      .cmp-teaser__action-container {
        @media only screen and (max-width: $tablet) {
          margin: 0;

          .cmp-teaser__action-link {
            margin: 0;
          }
        }
      }
  }
}

#teaser-coca-cola-logo {
  .cmp-teaser__content {
    &:before {
      content: "";
      display: block;
      margin: 2rem auto;
      background-image: url('resources/images/cocacola-icon.png'); 
      padding-bottom: 25%;
      background-size: contain;
      background-repeat: no-repeat;

      @media only screen and (min-width: $mobile) {
        margin: 1.5rem auto;
        padding-bottom: 0;
        width: 25rem;
        height: 6rem;
      }
    }
  }
}