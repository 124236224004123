.carousel--hero {
    .swiper__button-prev,
    .swiper__button-next {
        display: none;
    }

    .cmp-carousel {
        margin-top: 0;
        max-width: $maxMobileWidth;

        @include desktop {
            max-width: unset;
        }

        &__content {
            margin-left: 0;
        }

        &__item {
            @include desktop {
                max-width: unset;
            }

            .teaser {
                aspect-ratio: aspectRatio(heroCarousel, mobile);

                @include desktop {
                    aspect-ratio: aspectRatio(heroCarousel, desktop);
                }

                &.hidden {
                    display: none;
                }
            }
        }
    }

    .hero {
        @include vertical-spacing(margin-top, small);

        margin-bottom: 0;
        overflow: hidden;
        border-radius: $radius-small;

        @include desktop {
            border-radius: $radius-medium;
        }

        .cmp-teaser__content {
            inset: 0;
            width: unset;
            padding-inline: spacing(3);

            @include desktop {
                margin-inline: calc(max((100% - $desktopContent) / 2, spacing(8)));
                padding: 0;
            }
        }

        .cmp-teaser__image {
            width: 100%;
            height: 100%;
            margin-left: 0;
        }
        .cmp-teaser__image {
            aspect-ratio: unset;
        }
    }
}
