.teaser-vertical-icon {
    .cmp-teaser {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        margin-block: 1rem;
    }

    .cmp-teaser__title {
        font-size: 2.25rem;
        line-height: 1.2;
        font-weight: 700;
        padding-block: 1.5rem;

        @media only screen and (max-width: $mobile) {
            font-size: 1.5rem;
        }
    }

    .cmp-image__image{
        min-width: 3.5rem;
        width: auto;
        max-width: 100%;
        max-height: 5rem;
    }

    .cmp-teaser__description {
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 10%;

        p {
            margin-block-start: 0;
            margin-block-end: 1rem;

            @media only screen and (max-width: $mobile) {
                font-size: 0.875rem;
            }
        }

        @media only screen and (max-width: $mobile) {
            grid-template-columns: auto;
            grid-column-gap: 0;
        }
    }
}