@import '../../site/mixin';
@import '../../site/grid-mixins';
@import '../button/button_plain';

.carousel {
    @include rtl {
        direction: rtl;
    }
}

.carousel .cmp-carousel {
    @include vertical-spacing(margin-top, small);
    padding-inline: spacing(3);

    @include desktop {
        padding: 0;
    }
}

// not needed in OneXP Carousel
.cmp-carousel__actions,
.cmp-carousel__indicators {
    display: none;
}

.cmp-carousel__item.hidden {
    display: none;
}

.swiper-slide {
    height: auto;
    user-select: none;
}

.swiper__button-next,
.swiper__button-prev {
    display: inline-block;
    width: 3rem;
    height: 3rem; 
    background-color: $color-accent;
    border-radius: 50%;
    position: absolute;
    transform: translateY(-50%);
    padding: 0;
    border: 0;
    box-shadow: none;
    z-index: 9999;

    .carousel .cmp-carousel:has(.adaptiveImage) & {
        top: calc(50% - 21px); // -21px to compensate for the pagination mobile and tablet

        @media only screen and (min-width: $carouselDesktop) {
            top: calc(50% - 25px); // -25px to compensate for the pagination on desktop
        }
    }

    .carousel .cmp-carousel:has(.teaser) & {
        top: calc(41% - 21px); // -21px to compensate for the pagination mobile and tablet

        @media only screen and (min-width: $carouselDesktop)  {
            top: calc(41% - 25px); // -25px to compensate for the pagination on desktop
        }
    }

    @media only screen and (max-width: $carouselTablet) {
        width: 2rem;
        height: 2rem;
    }

    &::before {
        content: '';
        position: absolute;
        display: inline-block;
        top: 50%;
        width: 1.5rem;
        height: 1.5rem;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        @media only screen and (max-width: $carouselTablet) {
            width: 1rem;
            height: 1rem;
        }
}
}

.swiper__button-prev {
    left: 0;
    &::before {
        @include arrow-left-color(white);
        left: 50%;
        transform: translate(-60%, -50%);
    }
}

.swiper__button-next {
    right: 0;
    &::before {
        @include arrow-right-color(white);
        right: 50%;
        transform: translate(60%, -50%);
    }
}

.swiper-pagination.swiper-pagination-bullets {
    position: static;
    transform: none;
    z-index: initial;
    display: flex;
    justify-content: center;
    background-color: transparent;


    button.swiper-pagination-bullet {
        @include vertical-spacing(margin-top, small);

        width: 18px;
        height: 18px;
        border: solid 1px $gray05;
        background-color: transparent;
        margin-inline: 4px;
        opacity: 1;
        border-radius: 50%;
        padding: 0;
    }

    button.swiper-pagination-bullet-active {
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: $black;

        &::after {
            content: '';
            width: 10px;
            height: 10px;
            background-color: $black;
            border-radius: 10px;
        }
    }
}

.swiper-pagination .swiper-pagination-bullet:not(.swiper-pagination-bullet-active), 
.swiper__button-next:not(.swiper-button-disabled),
.swiper__button-prev:not(.swiper-button-disabled) {
        cursor: pointer;
}

// hide all but the first slide during swiper initialization
.carousel:not(.carousel--microcarousel) {
    .cmp-carousel__item {
        &--active {
            display: block;
        }

        &:is(.hidden, :not(.cmp-carousel__item--active)) {
            display: none;
        }
    }

    .cmp-carousel.swiper-initialized .cmp-carousel__item {
        display: block;
    }
}
