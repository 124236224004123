.title-header {
    .home-page &,
    #page-not-found & {
        display: none;
    }

    .cmp-container {
        background-position: center;
    }

    .cmp-title {
        width: 83.33333333%;
        margin:0 auto;

        .cmp-title__text {
            color: white;
            font-family: $font-family-title;
            font-size: 2.25rem;
            font-weight: 500;
            line-height: 1.2;
            padding-block: 1.875rem;

            @media only screen and (max-width: $mobile){
                font-size: 1.5rem;
                padding-block: 1.125rem;
            }
        }
    }
}