.table-header {
    background-color: $color-accent;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 9;
    margin-top: -1.3rem;
    max-width: 35rem;
    margin-left: auto;
    display: none;

    .cmp-text {
        padding-top: 2.5rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        padding-bottom: 1.688rem;

        p {
            margin: 5px 0;
            font-size: 0.875rem;
            line-height: 1.125rem;

            a {
                color: #fff;
                &:hover {
                    text-decoration-color: #fff;
                }
            }
        }
    }
    * {
        color: #fff;
    }
    table {
        margin: 1.25rem 0;
        max-width: 300px;
    }
  
    table td {
        font-size: 0.875rem;
        color: #ffffff;
        font-weight: 400;
        padding: 0.25rem 0.1rem;
        &:nth-of-type(1) {
            width: 40px;
        }
        &:nth-of-type(4) {
            width: 30px;
            text-align: center;
        }
    }
}