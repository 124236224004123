.container-hero-video {
    position: relative;
    z-index: 0;

    * {
        box-sizing: border-box;
    }

    .text {
        padding: 0 10px;
        width: 100%;

        .cmp-text {
            margin: 0;
        }
    }

    .embed {
        max-width: 100vw;
        overflow: hidden;
        position: relative;
        z-index: 0;
        min-height: calc(100vh - 320px);
        
        @media only screen and (min-width: $tablet) {
            min-height: calc(90vh - 140px);
        }

        .cmp-embed {
            position: initial;
        }
    }

    .cmp-embed__video__container {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        height: 56.25vw;
        min-height: 100vh;
        min-width: 177.77vh;
        width: 100vw;
    }
}

.aem-AuthorLayer-Edit  {
    .container-hero-video {
        .embed {
            min-height: 100%;
            position: initial;
        }

        .cmp-embed__video__container {
            position: initial;
        }

        video {
            position: initial;
            top: 0;
            left: 0;
            transform: translate(0, 0);
            height: auto;
            min-height: auto;
            min-width: auto;
            width: auto;
        }
    }
}