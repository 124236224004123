//== XF Footer style, used on page template

#main-footer {
  background-color: black;
  padding: 0 4.4rem;

  @media only screen and (min-width: $mobile) {
    display: grid;
    grid-template-columns: 14rem auto;
  }

  @media only screen and (max-width: $tablet) {
    padding: 0 2rem;
  }

  > div:nth-child(1) {
    .text:not(.weekhours) {
      p {
        font-size: 0.813rem;
        line-height: 1.8;
      }
    }

    @media only screen and (min-width: $mobile) {
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }
  > div:nth-child(2) {
    @media only screen and (min-width: $mobile) {
      grid-column-start: 2;
      grid-column-end: 3;
      padding-left: 6rem;
    }
  }
  > div:nth-child(3) {
    @media only screen and (min-width: $mobile) {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    @media only screen and (min-width: $laptop) {
      grid-column-start: 2;
      grid-column-end: 3;
      margin-left: 6rem;
    }

    padding-top: 1.563rem;
    padding-bottom: 1.563rem;
    border-top: 1px solid #555555;

    @media only screen and (max-width: $tablet) {
      padding-bottom: 0;
    }

    > .cmp-container {
      @media only screen and (max-width: $tablet) {
        flex-direction: column;
        align-items: center;
      }
    }
    a {
      font-size: 0.813rem;
    }

    .button {
      border-right: 1px solid #fff;
      margin-right: 1rem;
      padding-right: 1rem;
      @media only screen and (max-width: $tablet) {
        border-right: 0;
        padding: 0.5rem 0;
      }

      a {
        @media only screen and (max-width: $tablet) {
            color: #aeaeae;
        }
      }
      &:last-child {
        border-right: 0;
      }
    }
  }
  > div:nth-child(4) {
    font-size: 0.875rem;
    text-align: center;

    @media only screen and (min-width: $mobile) {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    @media only screen and (min-width: $laptop) {
      padding-left: 5rem;
    }
  }


  .hours-footer {
    width: 12rem;

    table {
      width: 100%;
    }
    p, td {
      color: #DFDFDF;
      font-size: .7rem;
      line-height: normal;
    }
  }

  .cmp-navigation {
    > .cmp-navigation__group {
      display: flex;
      justify-content: space-between;
      margin-top: 4.5rem;
      margin-bottom: 5.5rem;
      @media only screen and (max-width: $laptop) {
        flex-wrap: wrap;
      }
      @media only screen and (min-width: $tablet) and (max-width: $laptop){
        padding-left: 4rem;
      }

      > .cmp-navigation__item {
        @media only screen and (max-width: $laptop) {
          flex-basis: 50%;
          margin-bottom: 2rem;
        }

        .cmp-navigation__group {
          margin-top: .5rem;
        }

        > a {
          color: #fff;
          font-size: .9rem;
          text-transform: uppercase;
        }

        //Hides the /about-us section.
        //It is important to always have the /about-us as a last-child.
        &:last-child {
          display: none;
        }
      }

      .cmp-navigation__group {

        > .cmp-navigation__item {
          margin-bottom: 1.25rem;

          > a {
            color: #AEAEAE;
            font-size: .8rem;
          }
        }
      }

      //Add To Footer Paintbrush START
      > .add-to-footer {
        @media only screen and (max-width: $laptop) {
          flex-basis: 50%;
          margin-bottom: 2rem;
        }

        .cmp-text {
          margin: 0;
        }

        a {
          border: initial;

          &:hover {
            text-decoration: none;
          }
        }

        h5 a {
          color: #fff;
          text-transform: uppercase;
          font-size: .9rem;
          font-weight: normal;
        }

        ul {
          margin-top: .5rem;
          padding-left: 0;
          list-style: none;

          li {
            margin-bottom: 1.25rem;

            > a {
              color: #AEAEAE;
              font-size: .8rem;
            }
          }
        }

        //Text Components with add-to-footer paintbrush
        //are only visible when successfully moved under the footer navigation
        &.d-block{
          display: block;
        }
      }
      //Add To Footer Paintbrush END
    }
  }
  //Extra Add To Footer Paintbrush code 
  //Text Components with add-to-footer paintbrush should be hidden by default
  .text.add-to-footer {
    display: none;
  }

  .image {
    margin-top: 4.5rem;
  }

  img {
    max-width: 4.6rem;
    @media only screen and (max-width: $tablet) {
      margin: 0 auto;
    }
  }

}
