@mixin arrow-right-color($color) {
  background-image: url("data:image/svg+xml,%3Csvg width='9' height='12' viewBox='0 0 9 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 6L-4.89399e-07 11.1962L-3.51373e-08 0.803847L9 6Z' fill='#{$color}'/%3E%3C/svg%3E");
}

@mixin arrow-left-color($color) {
  background-image: url("data:image/svg+xml,%3Csvg width='9' height='12' viewBox='0 0 9 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.15493e-08 6L9 0.803848L9 11.1962L7.15493e-08 6Z' fill='#{$color}'/%3E%3C/svg%3E");
}

@mixin button_plain($color) {
  .cmp-button {
    display: inline-flex;
    background-color: transparent;
    border: none;
  }

  .cmp-button__text {
    color: $color;
    font-weight: 700;
    font-size: 0.875rem;
  }

  .cmp-button__icon {
    background-repeat: no-repeat;
    background-position: center;
    width: 1.25rem;
  }

  .cmp-button__icon--arrow-left {
    @include arrow-left-color($color);
    margin-right: 0.313rem;
  }

  .cmp-button__icon--arrow-right {
    @include arrow-right-color($color);
    order: 1;
    margin-left: 0.313rem;
  }
}

.button-plain {
  @include button_plain($color-accent);
}

.button-plain-white {
  @include button_plain(white);
}